import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import "./KlimaButton.css";
import { API } from "aws-amplify";
import Klimachart from "./Klimachart";

function zpad(number) {
    if( number <10 ) {
        return '0'+number;
    }
    return number;
}

export default class KlimaButton extends Component {

    constructor(props) {
        super(props);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    
        this.state = {
          sensorId : props.sensorId,
          isLoading: true,
          latestItem: {
            sensorID: "Develop",
            measureTS: "1999-12-31T23:59:00",
            localTime: new Date("1999-12-31T23:59:00"),
            temperature: "99.9",
            humidity: "99"
          },
          showModal: false,
          sensordata: []
        }
    }

    async fetchData() {
        var latestItem = await this.getLatest();
        latestItem.localTime = new Date(latestItem.measureTS);
        this.setState({ latestItem });

        const currentTimer = setTimeout(() => this.fetchData(), 60000 );
        this.setState({ currentTimer });
        return latestItem;
    }

    async componentDidMount() {
        if(!this.props.authState === "signedIn") {
            return;
        }

        try {
            await this.fetchData();

        } catch( e ) {
            alert( e );
        }

        this.setState({ isLoading: false });
    }

    componentWillUnmount() {
        clearTimeout( this.status.currentTimer );
    }

    getLatest() {
        return API.get("klimaapi","/latest/" + this.props.sensorId);
    }

    handleShow() {
        this.setState( { showModal: true });
    }

    handleClose() {
        this.setState( { showModal: false });
    }

    render() {
        const now = new Date(Date.now());
        const yearMonth = now.getUTCFullYear() + '-' + zpad(now.getUTCMonth()+1) + '-'
        return (
            <div>
            <Button className="station" bsStyle="primary" bsSize="large" onClick={this.handleShow}>
                <div className="raumdatum">
                    <div className="raum">{this.state.latestItem.sensorID}</div>
                    <div className="datum">{this.state.latestItem.localTime.toLocaleDateString() + " " + this.state.latestItem.localTime.toLocaleTimeString()}</div>
                </div>
                <div className="hc_separator"></div>
                <div className="temperatur">{this.state.latestItem.temperature}°C</div>
                <div className="feuchte">{this.state.latestItem.humidity}%</div>
            </Button>
            <Modal bsSize="large" show={this.state.showModal} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.sensorId}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Klimachart sensorId={this.state.sensorId} yearMonth={yearMonth} />
                </Modal.Body>
                <Modal.Footer>
                    <div id="Legende" className="legende"></div>
                </Modal.Footer>
            </Modal>
            </div>
        );
    }
}