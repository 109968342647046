import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify, {Auth} from "aws-amplify";
import config from "./config";

const oauth = {
    // Domain name
    domain : config.cognito.COGNITO_DOMAIN,

    // Authorized scopes
    scope : ['email',  'openid'], 

    // Callback URL
    redirectSignIn : config.cognito.REDIRECT_SIGN_IN, 

    // Sign out URL
    redirectSignOut : config.cognito.REDIRECT_SIGN_OUT,

    // 'code' for Authorization code grant, 
    // 'token' for Implicit grant
    responseType: 'code',

    // optional, for Cognito hosted ui specified options
    options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag : true
    }
}

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
        endpoints: [
            {
                name: "klimaapi",
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION
            }
        ]
    }
});

Auth.configure({oauth});

ReactDOM.render(
    <App />,
    document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
