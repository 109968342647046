import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import "./Home.css";
import KlimaButton from "./KlimaButton";

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sensoren: props.sensoren
        }
    }

    renderMesswerte() {
        return (
            this.state.sensoren.map( (si) =>
                <Col key={si} xs={12} sm={4} md={3} lg={2}>
                        <KlimaButton sensorId={si} authState={this.props.authState} />
                </Col>
            )
        );
    }

    renderLander(){
        return (
            <div className="lander">
                <h1>Klima</h1>
                <p>Klimaforschung von Detse und HC</p>
            </div>
        );
    }

    renderKlimas() {
        return(
            <Grid>
                <Row className="show-grid">
                {this.renderMesswerte()}
                </Row>
            </Grid>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.props.authState === 'signedIn' ? this.renderKlimas() : this.renderLander() }
            </div>
        );
    }
}