/* const olddev = {
    apiGateway: {
      REGION: "eu-west-1",
      URL: "https://6oxhzy25u5.execute-api.eu-west-1.amazonaws.com/dev"
    },
    cognito: {
      REGION: "eu-west-1",
      USER_POOL_ID: "eu-west-1_qnJSM5DKd",
      APP_CLIENT_ID: "6l8va5p4lam233e0k8eq1devg7",
      IDENTITY_POOL_ID: "eu-west-1:aa4a99db-f228-439c-9810-9edea1e18a89",
      COGNITO_DOMAIN: "klima-app.auth.eu-west-1.amazoncognito.com",
      REDIRECT_SIGN_IN: "http://localhost:3000/",
      REDIRECT_SIGN_OUT: "http://localhost:3000/login"
    },
    social: {
      GOOGLE: "783077724738-ne8b71v6oikvbuiejq8814vrdbesg3d1.apps.googleusercontent.com"
    }
};
 */

/*
 * Lokal
 * npm start
 *
 */

const dev = {
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://hd5bjjs6bj.execute-api.eu-central-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_yw61gHZl8",
    APP_CLIENT_ID: "hqtr2bveocm64rs8l722i8e64",
    IDENTITY_POOL_ID: "eu-central-1:d4ebec05-57db-41e2-b814-92149920a76c",
    COGNITO_DOMAIN: "klima-client-dev.auth.eu-central-1.amazoncognito.com",
    REDIRECT_SIGN_IN: "https://klima-dev.pahlig.de",
    REDIRECT_SIGN_OUT: "https://klima-dev.pahlig.de"
  },
  social: {
    GOOGLE: "783077724738-ne8b71v6oikvbuiejq8814vrdbesg3d1.apps.googleusercontent.com"
  }
};

const prod = {
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://17yo34zqpj.execute-api.eu-central-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_Zq3qMMzrY",
    APP_CLIENT_ID: "1h8470jfqvhvcc9jeiqks1s4ci",
    IDENTITY_POOL_ID: "eu-central-1:dd969f2c-61cb-4a1b-929f-14f0e84d7282",
    COGNITO_DOMAIN: "klima-client-prod.auth.eu-central-1.amazoncognito.com",
    REDIRECT_SIGN_IN: "https://klima.pahlig.de",
    REDIRECT_SIGN_OUT: "https://klima.pahlig.de"
  },
  social: {
    GOOGLE: "783077724738-ne8b71v6oikvbuiejq8814vrdbesg3d1.apps.googleusercontent.com"
  }
};

const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  ...config
};
