import React, { Component } from "react";
import { Nav, Navbar, NavDropdown, MenuItem, NavItem, Modal } from "react-bootstrap";
import "./App.css";
import Home from "./containers/Home";
import { Auth, Hub } from "aws-amplify";
import Klimachart from "./containers/Klimachart";
import OAuthNavItem from "./containers/OAuthNavItem";

// Anleitung AWS Cognito Google Anmeldung
// https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-social-idp.html

const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni",
                    "Juli", "August", "September", "Oktober", "November", "Dezember"];

class App extends Component {

  constructor(props) {
    super(props);

    this.stateChange = this.handleAuthState.bind(this);
    Auth.currentSession()
      .then(user => this.stateChange('signedIn'))
      .catch(err => this.stateChange('signOut'));
    this.state = {
      authState: 'loading',
      showModal: false,
      currentSensor: "Development",
      currentYearMonth: "2018-12-"
    };

    this.onHubCapsule = this.onHubCapsule.bind(this);
    this.signOut = this.signOut.bind(this);
    this.handleMenuSelect = this.handleMenuSelect.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    // let the Hub module listen on Auth events
    Hub.listen('auth', this);
  }

  handleAuthState(state) {
    this.setState({ authState: state });
  }

  async onHubCapsule(capsule) {
    // The Auth module will emit events when user signs in, signs out, etc
    const { channel, payload } = capsule;
    if (channel === 'auth') {
      switch (payload.event) {
        case 'signIn':
          console.log('signed in, payload: ', payload );
          const user = await Auth.currentUserInfo();
          console.log("currentUserInfo: ", user );
          this.setState({authState: 'signedIn'});
          break;
        case 'signIn_failure':
          console.log('not signed in');
          this.setState({authState: 'signOut'});
          break;
        default:
          break;
      }
    }
  }


  async componentDidMount() {
    try {
      await Auth.currentSession();
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  }

  signOut() {
    Auth.signOut().then(() => {
      this.setState({authState: 'signOut'});
    }).catch(e => {
      console.log(e);
    });
  }

  handleLogout = async event => {
    await Auth.signOut();
  }

  renderMonths(sensor) {
    var months = [];
    const d = new Date();
    var m = d.getMonth();
    var y = d.getFullYear();

    for( var i = 0; i < 14; i++ ) {
      const key = sensor + "." + y + "." + (m+1);
      months.push(<MenuItem eventKey={key} key={key} >
                    {monthNames[m]}
                    {i===0||m===11
                     ? " "+y
                     : ""}
                  </MenuItem>);
      m--;
      if( m < 0) {
        m=11;
        y--;
      }
    }
    return months;
  }

  async handleMenuSelect(selectedKey) {
    const [s,y,m] = selectedKey.split(".");
    await this.setState( {currentSensor: s} );
    await this.setState( {currentYearMonth: y + "-" + ((m>9)?m:"0"+m)} );

    this.setState( { showModal: true });
  }

  handleModalClose() {
    this.setState( { showModal: false });
  }


  render() {
    const childProps = {
      authState: this.state.authState,
      sensoren: ["Haus", "Schlafzimmer", "Keller"]
    };
    return (
      <div className="App container">
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              Klimaforschung
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            {this.state.authState === 'signedIn'
              ? <Nav onSelect={this.handleMenuSelect}>
                  {childProps.sensoren.map( (sensor) =>
                    <NavDropdown eventKey={sensor} title={sensor} key={"nav-id-"+sensor} id={"nav-id-"+sensor}>
                      {this.renderMonths(sensor)}
                    </NavDropdown>
                  )}
                </Nav>
              : ""
            }
            <Nav pullRight>
              {this.state.authState === 'signedIn'
                ? <NavItem onClick={this.handleLogout}>Logout</NavItem>
                : <OAuthNavItem />
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Home {...childProps} />
        <Modal bsSize="large" show={this.state.showModal} onHide={this.handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.currentSensor}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Klimachart sensorId={this.state.currentSensor} yearMonth={this.state.currentYearMonth} />
          </Modal.Body>
          <Modal.Footer>
            <div id="Legende" className="legende"></div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default App;
