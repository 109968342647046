import React, { Component } from 'react';
import Dygraph from 'dygraphs';
import { API } from "aws-amplify";
import "./Klimachart.css"

export default class Klimachart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sensorId: props.sensorId,
            yearMonth: props.yearMonth,
            isLoading: true,
            sensordata: []
        }

    }

    static getDerivedStateFromProps( props, state ) {
        state.sensorId = props.sensorId;
        state.yearMonth = props.yearMonth;
        return state;
    }


    getSensordata() {
        console.log("getSensorData", this.state.sensorId, this.state.yearMonth );
        return API.get("klimaapi", "/month/" + this.state.sensorId + "/" + this.state.yearMonth );
    }

    async fetchData() {
        const sensorjson = await this.getSensordata();
        const sensordata = sensorjson.map(
            obj => [new Date(obj.measureTS) , obj.temperature , obj.humidity]
        );
        this.setState({ sensordata });

        return sensordata;
    }


    async componentDidMount() {
        try {
            await this.fetchData();
        } catch( e ) {
            alert( e );
        }

        this.setState({ isLoading: false });

        const dygraphOptions = {
            showRoller: true,
            labels : [ "Datum", "Temp", "Feuchte"  ],
            yLabelWidth: 15,
            rollPeriod: 7,
            labelsDiv: document.getElementById("Legende"),
            underlayCallback: underlayCallback
        }


        this.graph = new Dygraph(this.refs.klimachart, this.state.sensordata, dygraphOptions );
    }

    render() {
        return (
            <div ref="klimachart" className="special" />
        );
    };
}

function underlayCallback(canvas, area, g) {

    canvas.fillStyle = "rgba(51, 122, 183, 0.5)";
    function highlight_period(x_start, x_end) {
            var canvas_left_x = g.toDomXCoord(x_start);
            var canvas_right_x = g.toDomXCoord(x_end);
            var canvas_width = canvas_right_x - canvas_left_x;
            canvas.fillRect(canvas_left_x, area.y, canvas_width, area.h);
    }

    var min_data_x = g.getValue(0,0);
    var max_data_x = g.getValue(g.numRows()-1,0);

    // get day of week
    var d = new Date(min_data_x);
    d.setHours(12);
    d.setMinutes(0);
    d.setSeconds(0);

    var dow = d.getUTCDay();

    var w = d.getTime();
    // starting on Sunday is a special case
    if (dow === 0) {
            highlight_period(w-12*3600*1000,w+12*3600*1000);
    }
    // find first saturday
    while (dow !== 6) {
            w += 24*3600*1000;
            d = new Date(w);
            dow = d.getUTCDay();
    }
    // shift back 1/2 day to center highlight around the point for the day
    w -= 12*3600*1000;
    while (w < max_data_x) {
            var start_x_highlight = w;
            var end_x_highlight = w + 2*24*3600*1000;
            // make sure we don't try to plot outside the graph
            if (start_x_highlight < min_data_x) {
                    start_x_highlight = min_data_x;
            }
            if (end_x_highlight > max_data_x) {
                    end_x_highlight = max_data_x;
            }
            highlight_period(start_x_highlight,end_x_highlight);
            // calculate start of highlight for next Saturday 
            w += 7*24*3600*1000;
    }
}
