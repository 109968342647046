import { withOAuth } from 'aws-amplify-react';
import React, { Component } from 'react';
import { NavItem } from 'react-bootstrap'; 

class OAuthNaviItem extends Component {
  render() {
    return (
      <NavItem onClick={this.props.OAuthSignIn}>Login</NavItem>
    )
  }
}

export default withOAuth(OAuthNaviItem);
